/* 淡出动画 */
@keyframes transOut {
    from {
      /* opacity: 1; */
      /* transform: translateX(0); */
    }
    to {
      /* opacity: 0; */
      /* transform: translateX(100%); */
    }
  }
   
  /* 淡入动画 */
  @keyframes transIn {
    from {
      /* opacity: 0; */
      transform: translateX(-100%);
    }
    to {
      /* opacity: 1; */
      transform: translateX(0);
    }
  }
   
  /* 应用淡出动画 */
  .trans-out {
    animation: transOut 0.5s ease-in forwards;
  }
   
  /* 应用淡入动画 */
  .trans-in {
    animation: transIn 0.5s ease-out forwards;
    /* background-color: aqua; */
  }



  /* 淡出动画 */
@keyframes fadeOut {
  from {
    /* opacity: 1; */
    /* transform: translateX(0); */
  }
  to {
    /* opacity: 0; */
    /* transform: translateX(100%); */
  }
}
 
/* 淡入动画 */
@keyframes fadeIn {
  from {
    opacity: 0;
    /* transform: translateX(-100%); */
  }
  to {
    opacity: 1;
    /* transform: translateX(0); */
  }
}
 
/* 应用淡出动画 */
.fade-out {
  animation: fadeOut 0.5s ease-in forwards;
}
 
/* 应用淡入动画 */
.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
  /* background-color: aqua; */
}


/* 覆盖组件类 */
.arco-carousel-card.arco-carousel-horizontal .arco-carousel-item-prev{
  transform: translateX(-160%) translateZ(-200px);
}

.arco-carousel-card.arco-carousel-horizontal .arco-carousel-item-next{
  transform: translateX(60%) translateZ(-200px);
}


@keyframes arco-carousel-card-bottom-to-middle {
  from {
    opacity: 0;
    transform: translateX(60%) translateZ(-400px);
  }
  to {
    opacity: 0.4;
    transform: translateX(60%) translateZ(-200px);
  }
}

@keyframes arco-carousel-card-top-to-middle {
  from {
    opacity: 1;
    transform: translateX(-160%) translateZ(0);
  }
  to {
    opacity: 0.4;
    transform: translateX(-160%) translateZ(-200px);
  }
}


.bg-img{
  background-image: url('@/assets/pic/20241224-170916.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  /* background: red; */
}


canvas.ChemDoodleWebComponent{
  border: none;
  /* border-width: ; */
}